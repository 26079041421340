import Link from 'next/link';
import { Fragment, useMemo, useState } from 'react';

import Dropdown from '@/components/header/Dropdown';
import DropdownItem from '@/components/header/DropdownItem';
import LinkItem from '@/components/header/LinkItem';
import ListItem from '@/components/header/ListItem';
import { HeaderProps } from '@/components/navigation/Navigation';
import Picture from '@/components/Picture/Picture';
import { projectConfig } from '@/constants/projectConfig';
import { useWindowSize } from '@/hooks/useWindowSize';

const Header = ({ menuItems, activePath }: HeaderProps) => {
  const [open, setOpen] = useState(false);

  const { width } = useWindowSize();

  const memoMenuItems = useMemo(() => {
    return menuItems.map((item) => ({
      ...item,
    }));
  }, [menuItems]);

  return (
    <header className="relative z-[2] flex w-full items-center bg-white">
      <div className="container mx-auto">
        <div className="relative -mx-4 flex items-center justify-between">
          <div className="w-60 max-w-full px-4">
            <Link href="/" className="block w-full py-5">
              <Picture
                imgSrc={`${projectConfig.cloudinaryProjectName}/collapo-logo`}
                alt="Collapo logo"
                classNameImage="h-[52px] w-auto xs:h-[80px] md:h-[115px]"
                classNameWrapper="mx-[22px]"
                width={160}
                height={60}
                enabledLazyLoading={false}
              />
            </Link>
          </div>
          <div className="flex w-full items-center justify-between px-4">
            <div>
              <button
                onClick={() => setOpen(!open)}
                className={` ${
                  open && 'navbarTogglerActive'
                } rounded-lg absolute right-4 top-1/2 block -translate-y-1/2 px-3 py-[6px] ring-primary focus:ring-2 lg:hidden`}
              >
                <span className="relative my-[6px] block h-[2px] w-[30px] bg-body-color"></span>
                <span className="relative my-[6px] block h-[2px] w-[30px] bg-body-color"></span>
                <span className="relative my-[6px] block h-[2px] w-[30px] bg-body-color"></span>
              </button>
              <nav
                className={`rounded-lg absolute right-4 top-full z-50 w-full max-w-[250px] bg-white py-5 shadow lg:static lg:block lg:w-full lg:max-w-full lg:bg-transparent lg:px-6 lg:py-0 lg:shadow-none  ${
                  !open && 'hidden'
                } `}
              >
                <ul className="block lg:flex">
                  {memoMenuItems
                    .filter(({ isButton }) => !isButton)
                    .map(({ link, subLink }, index) => (
                      <Fragment
                        key={`memoMenuItems-isNotButton-header-${index}`}
                      >
                        <ListItem>
                          <LinkItem
                            {...(subLink.length > 0 && { dropdown: true })}
                            navLink={link.path}
                            activePath={activePath}
                            item={link}
                          />
                          {subLink.length > 0 && (
                            <Dropdown>
                              {subLink.map(
                                ({ label, path, openInNewTab }, index) => (
                                  <Fragment
                                    key={`memoMenuItems-subHeader-${index}`}
                                  >
                                    <DropdownItem
                                      path={path}
                                      label={label}
                                      openInNewTab={openInNewTab}
                                      activePath={activePath}
                                    />
                                  </Fragment>
                                ),
                              )}
                            </Dropdown>
                          )}
                        </ListItem>
                      </Fragment>
                    ))}
                </ul>
              </nav>
            </div>
            <div className="hidden justify-end pr-16 sm:flex lg:pr-0">
              {memoMenuItems
                .filter(({ isButton }) => isButton)
                .map(({ link }, index) => (
                  <Fragment key={`memoMenuItems-header-isButton-${index}`}>
                    <Link
                      href={link.path}
                      className="rounded-md text-base bg-primary px-6 py-2.5 font-medium text-white hover:bg-primary/90"
                      target={
                        width && width > 1024 && link.openInNewTab
                          ? '_blank'
                          : '_self'
                      }
                    >
                      {link.label}
                    </Link>
                  </Fragment>
                ))}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
