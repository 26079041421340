import { ReactNode } from 'react';

interface LinkGroupProps {
  header: string;
  children: ReactNode;
}

const LinkGroup = ({ header, children }: LinkGroupProps) => {
  return (
    <div className="w-full px-4 sm:w-1/2 lg:w-1/4">
      <div className="mb-10 w-full">
        <h4 className="text-lg font-semibold mb-9 text-dark">{header}</h4>
        <ul className="space-y-3">{children}</ul>
      </div>
    </div>
  );
};

export default LinkGroup;
