import clsx from 'clsx';

import { useWindowSize } from '@/hooks/useWindowSize';

interface DropdownItemProps {
  label: string;
  path: string;
  openInNewTab?: boolean;
  activePath: string;
}

const DropdownItem = ({
  label,
  path,
  openInNewTab,
  activePath,
}: DropdownItemProps) => {
  const { width } = useWindowSize();
  return (
    <a
      href={path}
      className={clsx(
        'rounded text-sm block px-4 py-[10px] font-medium text-body-color hover:bg-primary hover:text-white',
        activePath === path && 'bg-primary text-white',
      )}
      target={width && width > 1024 && openInNewTab ? '_blank' : '_self'}
    >
      {label}
    </a>
  );
};

export default DropdownItem;
