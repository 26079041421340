interface BottomNavLinkProps {
  link: string;
  label: string;
}

const BottomNavLink = ({ link, label }: BottomNavLinkProps) => {
  return (
    <a href={link} className="text-base px-3 text-gray-7 hover:text-white">
      {label}
    </a>
  );
};

export default BottomNavLink;
