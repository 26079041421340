interface NavLinkProps {
  link: string;
  label: string;
}

const NavLink = ({ link, label }: NavLinkProps) => {
  return (
    <li>
      <a
        href={link}
        className="text-base inline-block leading-loose text-body-color hover:text-primary"
      >
        {label}
      </a>
    </li>
  );
};

export default NavLink;
