import clsx from 'clsx';

import { LinkProps } from '@/components/navigation/Navigation';
import { useWindowSize } from '@/hooks/useWindowSize';

interface LinkItemProps {
  navLink: string;
  dropdown?: boolean;
  activePath: string;
  item: LinkProps;
}

const LinkItem = ({
  navLink,
  dropdown = false,
  activePath,
  item,
}: LinkItemProps) => {
  const { width } = useWindowSize();

  return (
    <a
      href={navLink}
      className={clsx(
        'text-base relative flex px-6 py-2 font-medium text-body-color group-hover:text-dark lg:inline-flex lg:py-6  lg:pl-0 lg:pr-4',
        activePath === item.path && 'text-dark',
        dropdown &&
          'after:absolute after:right-5 after:top-1/2 after:mt-[-2px] after:size-2 after:translate-y-[-50%] after:rotate-45 after:border-b-2 after:border-r-2 after:border-current lg:after:right-0',
      )}
      target={width && width > 1024 && item.openInNewTab ? '_blank' : '_self'}
    >
      {item.label}
    </a>
  );
};

export default LinkItem;
