import { useRouter } from 'next/router';

import { NavigationQuery } from '@/api';
import Header from '@/components/header/Header';

interface NavigationProps {
  data: NavigationQuery;
}

export interface HeaderProps {
  activePath: string;
  menuItems: MenuItemProps[];
}

export interface MenuItemProps {
  label: string;
  link: LinkProps;
  subLink: LinkProps[];
  isButton: boolean;
}

export interface LinkProps {
  label: string;
  path: string;
  openInNewTab: boolean;
}

export const Navigation = ({ data }: NavigationProps) => {
  const navData = data?.navigation?.data?.attributes;
  const { pathname } = useRouter();
  if (!navData) return null;

  const headerProps: HeaderProps = {
    menuItems: (navData?.menu || []).map((item) => ({
      label: item?.label || '',
      link: {
        label: item?.link?.label || '',
        path: item?.link?.path || '',
        openInNewTab: !!item?.link?.openInNewTab,
      },
      subLink: (item?.subLink || []).map((link) => ({
        label: link?.label || '',
        path: link?.path || '',
        openInNewTab: !!link?.openInNewTab,
      })),
      isButton: !!item?.isButton,
    })),
    activePath: pathname,
  };

  return <Header {...headerProps} />;
};
